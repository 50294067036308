<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="O2C Flow Report" style="z-index:10">
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Operating Unit / Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="operatingUnit"
                        :options="optionOU"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.Code }}), ({{ props.option.OperatingUnitTerritory.Territory.code }}) {{ props.option.OperatingUnitTerritory.Territory.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.Code }}), ({{ props.option.OperatingUnitTerritory.Territory.code }}) {{ props.option.OperatingUnitTerritory.Territory.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Distribution Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="customerCategory"
                        :options="optionCustomerCategory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="salesPersonal"
                        :options="optionSalesPersonal"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>SO Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('date')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Posting Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('posting')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterPostingDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
        </vx-card>
        <vx-card title="" style="z-index:9">
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(1)" label="O2c Flow Report">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring :operatingUnit="operatingUnit" :customerCategory="customerCategory" :salesPersonal="salesPersonal"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab" :filterDate="filterDate" :filterPostingDate="filterPostingDate"></monitoring>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import monitoring from "./monitoring/index.vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    components: {
        monitoring,
        DateRangePicker
    },
    mounted() {
        this.getOptionOU();
        this.getOptionCustomerCategory();
        this.getOptionSalesPersonal();
        
        const today = new Date();
        this.filterDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.filterPostingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterPostingDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
    },
    data: () => ({
        tabColor:['success', 'primary', 'warning', 'info', 'success', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        salesChannel: {},
        customerCategory: {},
        optionCustomerCategory:[{}],
        salesPersonal: {},
        optionSalesPersonal:[{}],
        colorx: "success",
        selectedSO: {},
        operatingUnit: {
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        },
        optionOU: [{
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        }],
        filterDate: {
            startDate: null,
            endDate: null,
        },
        filterPostingDate: {
            startDate: null,
            endDate: null,
        },
    }),
    watch: {
        // territory: function(newValue, oldValue) {
        //     // console.log(newValue, oldValue);
        // }
        // customerCategory: function(newValue, oldValue) {
        //     console.log("customerCategory", newValue, oldValue);
        // }
    },
    methods: {
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
        resetDateFilter(name) {
            if(name=="date") {
                this.filterDate.startDate = null;
                this.filterDate.endDate = null;
            } else if(name=="posting") {
                this.filterPostingDate.startDate = null;
                this.filterPostingDate.endDate = null;
            }
        },
        getOptionOU() {
            this.$http.get("/api/v1/master/operating-unit").then(resp => {
                this.optionOU = resp.data.records;
                this.operatingUnit = resp.data.records[0];
            });
        },
        getOptionCustomerCategory() {
            this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = this.optionCustomerCategory[0];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionSalesPersonal() {
            this.$http.get("/api/v1/sales-order/sales-personal").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSalesPersonal = []
                        this.optionSalesPersonal.push({id: 0, name:'All'})
                        let data = resp.data.SalesPersonalSO
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSalesPersonal.push(data[idx])
                        }
                        this.salesPersonal = this.optionSalesPersonal[0];
                    }else{
                        // this.$vs.loading.close();
                        // this.$router.push("/");
                    }
                }
            );
        },
        updateSelectedSalesOrder(salesOrder) {
            this.selectedSO = salesOrder;
            this.changeTab(1);
            // console.log("selected", this.selectedSO);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>